<template>
  <div>
    <v-menu
      :close-on-content-click="true"
      offset-y
      bottom
      left
      origin="top right"
      max-height="840"
      transition="scale-transition"
    >
      <template v-slot:activator="{ attrs, on }">
        <v-btn text v-bind="attrs" v-on="on">
          <v-badge bordered color="red" overlap>
            <template v-slot:badge>
              <span> {{ nbalerts }} </span>
            </template>
            <v-icon>mdi-bell</v-icon>
          </v-badge>
        </v-btn>
      </template>
      <v-card>
        <div v-for="(item, i) in list" :key="i" @click="goto(item)">
          <v-alert
            color="orange"
            dense
            text
            border="left"
            elevation="2"
            colored-border
            icon="mdi-alert"
            class="my-1"
          >
            <a>{{ item.ref }}- &nbsp;{{ item.label }}</a>
          </v-alert>
          <!-- <v-list-item-title v-text="item.message"></v-list-item-title> -->
        </div>
      </v-card>
    </v-menu>
    <reparationform
      :dialog="rep_dialog"
      :item="reparation"
      :details="reparation.details"
      :wells="wells"
      :user_list="user_list"
      @close_reparation="close_reparation"
      :key="krep"
      :isNotified="1"
    >
    </reparationform>
  </div>
</template>

<script>
import ALERTS from "@/graphql/Notification.gql";
import REPARATION from "@/graphql/Reparation/Reparation.gql";
export default {
  name: "DefaultNotifications",
  components: {
    reparationform: () => import("@/components/RepairForm.vue"),
  },
  data: () => ({
    user_list: [],
    reparation: {},
    wells: [],
    rep_dialog: false,
    krep: 20000,
    notifications: [],
    ref: false,
  }),
  mounted() {
    setInterval(() => {
      this.refresh();
    }, 60000);
  },
  watch: {
    "$store.state.changed": {
      handler() {
        if (this.$store.state.changed) {
          this.refresh();
        }
      },
    },
  },
  created() {
    this.refresh();
  },

  computed: {
    list() {
      return this.notifications;
      //   return this.notifications.filter(
      //     (elm) =>
      //       (this.$store.state.alerts.map((elm) => elm.id).includes(elm.code) &&
      //         ((elm.code <= 6 && this.$store.state.auth.includes(elm.auth)) ||
      //           elm.code > 6)) ||
      //       this.$store.state.isadmin
      //   );
    },
    nbalerts() {
      return this.notifications.length;
    },
  },
  methods: {
    async refresh() {
      let r = await this.$requette(ALERTS);
      if (r) {
        this.notifications = r.data.notifications_list;
        this.user_list = r.data.users;
      }
    },
    goto(item) {
      if (item.notification_id == 7) this.open_reparation(item);
    },

    async open_reparation(item) {
      let r = await this.$requette(
        REPARATION,
        {
          RepScope: parseInt(item.ref_id),
        },
        "no-cache"
      );
      if (r.ok) {
        this.well = [];
        this.reparation = r.data.getreparation[0];
        //this.details = this.reparation.details;
        this.user_list = r.data.users;
        this.wells.push({
          well_id: r.data.getreparation[0].well_id,
          wellname: item.ref,
        });
        this.krep++;
        this.rep_dialog = true;
        this.krep++;
      }
    },
    close_reparation() {
      this.rep_dialog = false;
    },
  },
};
</script>
